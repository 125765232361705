import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import iconRight from "@/assets/icon_chevron-right@2x.png";
import "./index.less";
import formatDate from "@/utils/dateFormater";
import {
	Button,
	Row,
	Col,
	Space,
	Table,
	Form,
	Select,
	Spin,
	Tag,
	Collapse,
	Pagination,
	Typography,
	Input,
} from "antd";
import CustomModal from "../../../components/modal";
import imgSource from "../../../assets";
import sortData from "../../../utils/sorter";
import { effect, useStore, reducer } from "../../../utils/dva16";
import iconArrow from "@/assets/icon/arrow-down_mobile-table.png";
import reverseArrow from "@/assets/icon/arrow-down_mobile-table - reverse.png";
import { useMediaQuery } from "react-responsive";
import {
	EGet,
	EGetTopFive,
	EGetArea,
	EGetChart,
	EGetCompany,
	EGetOne,
	EGetType,
	EComp,
	EGetCommonArea,
	NCompany,
	NExchange,
	NOrganizationMulti,
	NOrganizationMultiType,
	NInsightsFeed,
	NRegion,
	RSetState,
	NCommon,
	EGetActivetType,
	NLogin,
	EGetRegion,
	NGame,
	EGeTPage,
	NExclusives,
} from "../../../models/contants";
import { Pie, G2 } from "@ant-design/charts";
import Reaulatory3 from "../../../assets/menu/Regulatory3.png";
import E from "../../../models/E";
import { Helmet } from "react-helmet";
import { customizeRenderEmpty } from "../../../utils/utils";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import defaultLogo from "../../../assets/images/company/defaul-logo_organization.png";
import CustomEmpty from "@/utils/CustomEmpty";
import Organization from "../../../models/OrganizationMulti";
const { Option } = Select;
export default (props) => {
	const { Panel } = Collapse;
	const isMobile = useMediaQuery({ maxWidth: 767 }); // Mobile breakpoint
	const { history } = props;
	const [form] = Form.useForm();
	const { areaList, regionList, commonAreaList } = useStore(NRegion);
	const [filterValues, setFilterValues] = useState([]);
	const { CompList, OrganizationMultiChart, loading, OrganizationCompany } =
		useStore(NOrganizationMulti);
	// const { gameSearch } = useStore(NGame);
	const [searchValue, setSearchValue] = useState(null);
	// const { OrganizationMultiTypeList } = useStore(NOrganizationMultiType);
	const { topFiveList } = useStore(NInsightsFeed);

	const [sortState, setSortState] = useState(null);
	let sortType = {
		ascend: "asc",
		descend: "desc",
	};

	const { Text } = Typography;
	function GamesColumn(text, record, index) {
		const showAll = showAllTags[index] || false;
		const games = record?.withGamesList ? [...record?.withGamesList] : [];
		const showCollapse = games?.length > 2;
		const defaultActiveKey = showCollapse ? ["2"] : [];

		return (
			<div>
				{games
					?.slice(0, showAll ? record.withGamesList.length : 10)
					?.map((game, i) => (
						<Tag
							onClick={() => {
								toDetails(game?.id, true);
							}}
							className="greenTag"
							key={i}
							style={{ marginBottom: 4 }}
						>
							{game?.gameEn}
						</Tag>
					))}
				{(record?.withGamesList?.length > 10 ||
					record?.withCompanys?.length > 10 ||
					record?.withTeams?.length > 10 ||
					record?.withPlatforms?.length > 10 ||
					record?.genres?.length > 10) && (
					<Tag className="greyTag" onClick={() => toggleShowAll(index)}>
						{showAll ? "Show less..." : "Show all..."}
					</Tag>
				)}
			</div>
		);
	}
	const [activeKey, setActiveKey] = useState([]);
	const [hasMore, setHasMore] = useState(true);
	const [organizationList, setOrganizationList] = useState([]);
	let [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const handleCollapseChange = (key) => {
		setActiveKey(key);
	};
	useEffect(() => {
		effect(NGame, EGetType, { page: 1, perPage: 10 });
		effect(NCommon, EGetActivetType, { page: 1, perPage: 100 });
		effect(NExchange, EGet, { page: 1, perPage: 10 });
		effect(NRegion, EGetArea, { page: 1, perPage: 200 });
		effect(NRegion, EGetCommonArea);
		effect(NOrganizationMulti, EGetCompany, { page: 1, perPage: 10 });
		effect(NRegion, EGetRegion, { page: 1, perPage: 10 });
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10 });
		effect(NInsightsFeed, EGetTopFive);
		effect(NOrganizationMulti, EGetChart, {
			category: ["Company", "Regulatory", "Government"],
		});
		return () => {
			reducer(NCompany, RSetState, { companyChartData: null });
		};
	}, []);
	useEffect(() => {
		effect(NOrganizationMulti, EComp, { page, pageSize });
		setPage((prev) => prev + 1);
	}, []);

	const loadMoreData = async () => {
		if (loading || !hasMore) return;

		try {
			// Set payload with the current `page`
			const payload = { page, pageSize };
			setHasMore(CompList?.length === pageSize); // Check if there are more items

			// Await the effect
			effect(NOrganizationMulti, EComp, payload); // Ensure effect is awaited

			setOrganizationList((prevList) => {
				const map = new Map();
				[...prevList, ...CompList].forEach((item) => {
					map.set(item.id, item); // Use 'id' or another unique key
				});

				const uniqueArray = Array.from(map.values());
				console.log("Before sort:", uniqueArray);

				const sortedArray = uniqueArray.sort((a, b) => {
					const revA = parseFloat(a.latestRevenue) || 0; // Convert to number, fallback to 0
					const revB = parseFloat(b.latestRevenue) || 0; // Convert to number, fallback to 0
					return revB - revA; // Sort in descending order
				});

				console.log("After sort:", sortedArray);
				return sortedArray;
			});
			// Use the prev page value to ensure correct state update
			if (CompList?.length === pageSize) {
				setPage((prev) => prev + 1); // Increment page number
			}
		} catch (error) {
			console.error("Error loading more data:", error);
		}
	};
	useEffect(() => {
		if (CompList && CompList?.length > 0) {
			setOrganizationList((prevList) => {
				const map = new Map();
				[...prevList, ...CompList].forEach((item) => {
					map.set(item.id, item); // Use 'id' or another unique key
				});

				const uniqueArray = Array.from(map.values());
				console.log("Before sort:", uniqueArray);

				const sortedArray = uniqueArray.sort((a, b) => {
					const revA = parseFloat(a.latestRevenue) || 0; // Convert to number, fallback to 0
					const revB = parseFloat(b.latestRevenue) || 0; // Convert to number, fallback to 0
					return revB - revA; // Sort in descending order
				});

				console.log("After sort:", sortedArray);
				return sortedArray;
			});
		}
	}, [CompList]);

	useEffect(() => {
		const handleScroll = () => {
			const container = document.querySelector(".main_main");
			if (!container) return;

			if (
				container.scrollHeight - container.scrollTop <=
					container.clientHeight + 100 &&
				!loading
			) {
				loadMoreData();
			}
		};

		const container = document.querySelector(".main_main");
		if (container) {
			container.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener("scroll", handleScroll);
			}
		};
	}, [loading, hasMore]);

	const [showAllTags, setShowAllTags] = useState({});
	const toggleShowAll = (index) => {
		setShowAllTags((prevState) => ({
			...prevState,
			[index]: !prevState[index],
		}));
	};
	const columns = [
		{
			title: "Company",
			dataIndex: "name",
			showSorterTooltip: false,
			key: "name",
			width: 250,
			align: "left",
			sortOrder: sortState?.field == "name" && sortState?.order,
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			render: (text, record, index) => (
				<>
					<div className="Tabel_Company">
						<img
							className="Tabel_Company_Logo"
							src={record?.logo ? record.logo : defaultLogo}
							alt={record?.name}
						/>
						<div className="Tabel_Company_Title">
							<p>
								<a
									className="a_style"
									key={record?.id}
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.name}
								</a>
							</p>
							<p>
								<a
									className="a_style"
									onClick={() => {
										toDetails(record?.id);
									}}
								>
									{record?.localName}
								</a>
							</p>
						</div>
					</div>
				</>
			),
		},

		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			align: "left",
			width: 200,
			sortOrder: sortState?.field == "type" && sortState?.order,

			sorter: (a, b) => {
				let as = a?.type?.[0] || "";
				let bs = b?.type?.[0] || "";
				return as.localeCompare(bs);
			},
			render: (text, record, index) => (
				<>
					{record?.type?.map((item, index) => {
						return <div key={index}>{item + "\n"}</div>;
					})}
				</>
			),
		},
		{
			title: "HQ Location",
			dataIndex: "area",
			width: 150,
			key: "area",
			align: "left",
			sortOrder: sortState?.field == "area" && sortState?.order,
			// sorter: (a, b) => {
			// 	a?.area?.[0] &&
			// 		b?.area?.[0] &&
			// 		a?.area?.[0]?.localeCompare(b?.area?.[0]);
			// },
			sorter: (a, b) => {
				let areaA = a?.withArea?.[0] || "";
				let areaB = b?.withArea?.[0] || "";
				console.log(areaA);
				console.log(areaB);
				return areaA.localeCompare(areaB);
			},
			render: (text, record) => (
				<div>
					{record?.area?.length
						? record?.withArea?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			width: 150,
			align: "left",
			sortOrder: sortState?.field == "region" && sortState?.order,
			// sorter: (a, b) => {
			// 	a?.region?.[0] &&
			// 		b?.region?.[0] &&
			// 		a?.region?.[0]?.localeCompare(b?.region?.[0]);
			// },
			sorter: (a, b) => {
				let regionA = a?.withRegion?.[0] || "";
				let regionB = b?.withRegion?.[0] || "";
				return regionA.localeCompare(regionB);
			},
			render: (text, record) => (
				<div>
					{record?.region?.length
						? record?.region?.map((vo, io) => <div key={io}>{vo}</div>)
						: "-"}
				</div>
			),
		},
		{
			title: "Related Games",
			key: "relatedGames",
			dataIndex: "relatedGames",
			align: "center",
			className: "custom-publisher-column",
			width: 200,
			render: GamesColumn,
			sorter: true,
			sortOrder: sortState?.field == "relatedGames" && sortState?.order,
		},
		{
			title: "Year",
			key: "latestYear",
			dataIndex: "latestYear",
			align: "center",
			className: "custom-publisher-column",
			width: 200,
			sorter: (a, b) => {
				const latestYearA = a?.latestYear ?? 0; // Fallback to 0 if undefined or null
				const latestYearB = b?.latestYear ?? 0;

				return latestYearA - latestYearB; // Numeric comparison
			},
			sortOrder: sortState?.field === "latestYear" && sortState?.order,
			render: (text, record) => {
				return (
					<div>{`${record?.latestYear} ${record?.quarterCode}` || ""}</div>
				); // Display the year or an empty string
			},
		},

		{
			title: "Revenue ($Bn)",
			key: "latestRevenue",
			dataIndex: "latestRevenue",
			align: "left",

			width: 200,

			sorter: (a, b) => {
				const latestRevenueA = a?.latestRevenue ?? 0; // Fallback to 0 if undefined or null
				const latestRevenueB = b?.latestRevenue ?? 0;

				return latestRevenueA - latestRevenueB; // Numeric comparison
			},
			sortOrder: sortState?.field == "latestRevenue" && sortState?.order,
			render: (text, record) => {
				const formattedRevenue = record?.latestRevenue
					? Number(record?.latestRevenue).toLocaleString(undefined, {
							useGrouping: true, // Ensures commas are included
							maximumFractionDigits: 0, // Removes decimals
					  })
					: "0";

				return <div>${formattedRevenue}</div>;
			},
		},
		{
			title: "Information",
			dataIndex: "Information",
			key: "Information",
			width: 200,
			align: "left",
			render: (text, record, index) => {
				return (
					<div className="Tabel_Information">
						{record?.__meta__?.exclusives_count >= 1 && (
							// record?.skipExclusives
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.exclusives_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.exclusives_count >= 1) {
										history.push({
											pathname: "/niko_knowledge_briefs",
											state: { company: record?.id },
										});
									}
								}}
							>
								Knowledge Briefs
							</a>
						)}

						{record?.__meta__?.InsightFeed_count >= 1 && (
							// record?.skipInsightFeed
							// 	?.map((rec) => rec?.status)
							// 	?.includes("Published") &&
							<a
								style={
									record?.__meta__?.InsightFeed_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.InsightFeed_count >= 1) {
										history.push({
											pathname: "/news_articles/VANA",
											state: { company: record?.id, compName: record?.name },
										});
									}
								}}
							>
								VANA Articles
							</a>
						)}

						{record?.__meta__?.Tournament_count >= 1 && (
							<a
								style={
									record?.__meta__?.Tournament_count >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.__meta__?.Tournament_count >= 1) {
										history.push({
											pathname: "/esports",
											state: { hosts: record?.id, hostName: record?.name },
										});
									}
								}}
							>
								Esports
							</a>
						)}
						{record?.__meta__?.Blogs_count >= 1 && (
							<a
								style={
									record?.__meta__?.Blogs_count >= 1
										? {}
										: { color: "black", cursor: "default" }
								}
								className="a_style"
								// onClick={(e) => {
								// 	if (record?.__meta__?.Blogs_count >= 1) {
								// 		const url = `#/news_articles/blog_posts?company=${encodeURIComponent(
								// 			record?.name
								// 		)}&companyID=${encodeURIComponent(record?.id)}`;

								// 		window.open(url, "_blank", "noopener,noreferrer");
								// 	} else {
								// 		e.preventDefault();
								// 	}
								// }}
								onClick={() => {
									if (record?.__meta__?.Blogs_count >= 1) {
										history.push({
											pathname: "/blog_posts",
											state: { hosts: record?.id, hostName: record?.name },
										});
									}
								}}
							>
								Blog Posts
							</a>
						)}
						{/* {record?.skipTeams?.length >= 1 && (
							<a
								style={
									record?.skipTeams?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipTeams?.length >= 1) {
										history.push({
											pathname: "/Esports/Teams",
											state: { sponsor: record?.id },
										});
										window.scrollTo({
											top: 0,
											behavior: "smooth",
										});
									}
								}}
							>
								Teams
							</a>
						)} */}

						{/* {record?.skipIpo?.length >= 1 && (
							<a
								style={
									record?.skipIpo?.length >= 1
										? {}
										: {
												color: "black",
												textDecoration: "none",
												cursor: "default",
										  }
								}
								className="a_style"
								onClick={() => {
									if (record?.skipIpo?.length >= 1) {
										history.push({
											pathname: "/Financial/Transactions",
											state: { companyId: record?.id },
										});
									}
								}}
							>
								M&A
							</a>
						)} */}
					</div>
				);
			},
		},
	];

	const toDetails = (value, isGame = false) => {
		if (isGame) {
			history.push(`/games/details/${value}`);
		} else {
			history.push(`/organizations/details/${value}`);
		}
	};

	const toVANA = (value) => {
		history.push(`/news_articles/vana/details/${value}`);
	};
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const onFinish = (values) => {
		values.areas = values.areas && [
			...values?.areas?.map((areaValue) => {
				return commonAreaList?.data?.find(
					(item) => item?.otherData === areaValue
				)?.id;
			}),
			...values.areas,
		];
		const valuesArray = Object.values(values).filter(
			(val) =>
				val !== undefined &&
				val !== null &&
				!(Array.isArray(val) && val.length === 0)
		);
		setFilterValues(valuesArray);
		setSearchValue(values);
		closeModal();

		effect(NOrganizationMulti, EComp, {
			...values,
			page: 1,
			perPage: 10,
			field: sortState?.field,
			isDesc: sortState?.order && sortType[sortState?.order],
		});
	};
	// const searchByTags = (state) => {
	// 	effect(NExclusives, EGet, {
	// 		page: 1,
	// 		perPage: 10,
	// 		field: "date",
	// 		isDesc: "desc",
	// 		...state,
	// 	});
	// 	if (state) {
	// 		form.setFieldsValue({
	// 			...state,
	// 		});
	// 	}
	// };
	const onSearchCompany = (value) => {
		effect(NOrganizationMulti, EGetCompany, {
			name: value,
			page: 1,
			perPage: 10,
		});
	};
	const onSearchType = (value) => {
		effect(NOrganizationMultiType, EGet, { page: 1, perPage: 10, name: value });
	};
	const onReset = () => {
		setSearchValue();
		setFormValues([]);
		sessionStorage.setItem("orgPage", 1);
		sessionStorage.setItem("orgPageSize", 10);
		form.resetFields();
		closeModal();
		effect(NOrganizationMulti, EComp, {
			page: 1,
			perPage: 10,
		});
	};

	function findRegionType(arr) {
		const updatedRegionArr = [];
		areaList?.data?.forEach((item) => {
			if (arr?.includes(item?.otherData)) {
				updatedRegionArr?.push(item?.lookupValue);
			}
		});

		form.setFieldsValue({
			regions: Array.from(new Set(updatedRegionArr)),
		});
		setFormValues((prevValues) => ({
			...prevValues,
			["regions"]: Array.from(new Set(updatedRegionArr)),
		}));
	}
	function onSearchGame(value) {
		effect(NGame, EGetType, { ...{ name: value }, page: 1, perPage: 10 });
	}

	const [formValues, setFormValues] = useState({});

	// Handle form value changes
	const handleValuesChange = (changedValues) => {
		setFormValues((prevValues) => ({
			...prevValues,
			...changedValues,
		}));
	};

	// Reset field function
	const resetField = (fieldName) => {
		if (fieldName === "areas") {
			findRegionType([]); // Clear region based on empty area
		}
		form.setFieldsValue({ [fieldName]: [] });
		setFormValues((prevValues) => ({
			...prevValues,
			[fieldName]: [],
		}));
		let values = form.getFieldsValue();
		setFilterValues(values);
	};
	const copyVanaTop = (e, vo) => {
		e.preventDefault();
		if (vo?.content) {
			navigator.clipboard
				.writeText(vo?.content?.replace(/<[^>]+>/g, ""))
				.then(() => {
					console.log("Content copied to clipboard!");
				})
				.catch((err) => {
					console.error("Failed to copy: ", err);
				});
		}
	};
	return (
		<div>
			<Helmet>
				<script
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-N9T2Q4PW');
                        `,
					}}
				/>
				{/* Google tag (gtag.js) */}
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-6049TJDB50"
				></script>
				<script>
					{`
         				window.dataLayer = window.dataLayer || [];
        				function gtag(){dataLayer.push(arguments);}
          			gtag('js', new Date());
         				gtag('config', 'G-6049TJDB50');
        	`}
				</script>
			</Helmet>
			<>
				<div>
					<p className="mainheader">Company News & Data</p>
					<div className="subHeader" style={{ marginBottom: "20px" }}>
						Gain context on the top game companies and regulators in Asia &
						MENA.
					</div>
				</div>
				<div>
					<p className="mainheader2">Financial & Investment News</p>
				</div>
				<Spin spinning={loading} size="large">
					<div className="BlogBox">
						<Row className="RowBox mt30">
							<Col span={24}>
								<ul className="ProlistBox">
									{topFiveList?.data?.length > 0 &&
									topFiveList?.error != 1009 ? (
										topFiveList?.data?.map((item, index) => {
											const date = new Date(item?.date);
											const formattedDate = formatDate(date);
											const showAll = showAllTags[index] || false;

											return (
												<li key={index} style={{ listStyle: "none" }}>
													<div
														className="insight_list_cont"
														style={{ width: "100%" }}
													>
														<h3>
															<div>
																<div
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		toVANA(item?.id);
																	}}
																>
																	{item?.title}
																</div>
															</div>
															<div className="insight_list_date_style">
																{formattedDate}
															</div>
														</h3>
														<div className="areaRegionBox">
															{item?.area?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: "Financial & Investment",
																				area: item?.area,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.map_icon}
																		style={{
																			marginRight: "5px",
																			width: "20px",
																		}}
																	></img>
																	{item?.area?.length > 0 &&
																		item?.area?.join(", ")}
																</span>
															)}
															{item?.region?.length > 0 && (
																<span
																	className="listLink"
																	onClick={() => {
																		history.push({
																			pathname: "/news_articles/VANA",
																			state: {
																				type: "Financial & Investment",
																				region: item?.region,
																			},
																		});
																	}}
																>
																	<img
																		src={imgSource?.globe_gray_icon}
																		style={{
																			marginRight: "5px",
																			width: "20px",
																		}}
																	></img>
																	{item?.region?.length > 0 &&
																		item?.region?.join(", ")}
																</span>
															)}
															{/* {item?.type && (
																<span
																	className="listLink"
																	onClick={() => {
																		const state = {
																			type: item?.type,
																		};
																		searchByTags(state);
																	}}
																>
																	<img
																		src={imgSource?.folder_icon}
																		style={{
																			marginRight: "5px",
																			width: "20px",
																		}}
																	></img>
																	{item?.type}
																</span>
															)} */}
														</div>
														<div className="Tag_List_Box">
															{item?.withGames
																?.slice(
																	0,
																	showAll ? item?.withGames?.length : 10
																)
																?.map((vi, iii) => (
																	<span
																		key={iii}
																		className="game-title"
																		onClick={() => {
																			history.push(`/games/details/${vi?.id}`);
																		}}
																	>
																		{vi?.gameEn}
																	</span>
																))}
															{item?.withCompanys
																?.slice(
																	0,
																	showAll ? item?.withCompanys?.length : 10
																)
																?.map((vi, ii) => (
																	<span
																		key={ii}
																		className="org-title"
																		onClick={() => {
																			history.push(
																				`/organizations/details/${vi?.id}`
																			);
																		}}
																	>
																		{vi?.name}
																	</span>
																))}

															{(item?.withCompanys?.length > 10 ||
																item?.withGames?.length > 10) && (
																<span
																	className="show-all"
																	onClick={() => toggleShowAll(index)}
																>
																	{showAll ? "Show less..." : "Show all..."}
																</span>
															)}
														</div>
														<div
															className="insght_lst_info"
															onClick={() => {
																toVANA(item?.id);
															}}
														>
															{(() => {
																const content = item?.content?.replace(
																	/<[^>]+>/g,
																	""
																);
																const words = content?.split(" ");

																if (words?.length > 50) {
																	return words?.slice(0, 50)?.join(" ") + "...";
																} else {
																	return content;
																}
															})()}
														</div>
														<div
															className="link"
															style={{ marginBottom: "20px" }}
														>
															<a
																href={item?.link}
																target="_blank"
																onClick={(e) => copyVanaTop(e, item)}
																style={{ marginRight: "2rem" }}
															>
																<img
																	src={imgSource?.copy_icon}
																	style={{ marginRight: "5px" }}
																></img>
																<span>Copy VANA</span>
															</a>
															{item?.link && (
																<a href={item?.link} target="_blank">
																	<img
																		src={imgSource?.external_link_icon}
																		style={{ marginRight: "5px" }}
																	></img>
																	<span>Visit Website</span>
																</a>
															)}
														</div>
													</div>
												</li>
											);
										})
									) : (
										<CustomEmpty />
									)}
								</ul>
							</Col>
						</Row>
					</div>
				</Spin>
				<div className="compBox">
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							className="wide"
							onClick={() => {
								history.push({
									pathname: "/news_articles/VANA",
									state: { type: "Financial & Investment" },
								});
								window.scrollTo(0, 0); // Scroll to the top of the page
							}}
						>
							View More{" "}
							<img
								style={{ width: "8px", marginLeft: "10px" }}
								src={iconRight}
							/>
						</Button>
					</div>
				</div>
				<Spin spinning={loading} size="large">
					<div className="CompanyBox" style={{ marginTop: "10rem" }}>
						<div>
							<p className="mainheader3">
								Top Public Game Companies by Revenue with significance in Asia &
								MENA
							</p>
						</div>
						<Row className="RowBox mt30" justify="right">
							<Col span={24}>
								{CompList?.error == 1009 ? (
									customizeRenderEmpty()
								) : !isMobile ? (
									<Table
										showSorterTooltip={false}
										scroll={{ x: "max-content" }}
										dataSource={organizationList || []}
										columns={columns}
										className="CompanyTableBox"
										pagination={false}
										onChange={(pagination, filters, sort) => {
											setSortState(sort);

											sessionStorage.setItem("orgPage", pagination?.page);
											sessionStorage.setItem(
												"orgPageSize",
												pagination?.pageSize
											);
										}}
									/>
								) : (
									<Collapse
										accordion
										activeKey={activeKey}
										onChange={handleCollapseChange}
										className="MobileList"
										expandIconPosition="right"
										expandIcon={({ isActive }) => (
											<div className="custom-arrow">
												<img
													src={isActive ? reverseArrow : iconArrow}
													alt="arrow"
												/>
											</div>
										)}
									>
										{organizationList?.map((record) => (
											<Panel
												header={
													<div className="panel-header">
														<div
															style={{ display: "flex", alignItems: "center" }}
														>
															{
																<img
																	src={record?.logo ? record.logo : defaultLogo} // Assuming item has an imageSrc property
																	alt="tournament"
																	className="tournament-image"
																/>
															}
															<a
																className="a_style"
																key={record?.id}
																onClick={() => {
																	toDetails(record?.id);
																}}
															>
																<span>
																	{" "}
																	{record?.name && <div>{record?.name}</div>}
																	{record?.localName && (
																		<div>{record?.localName}</div>
																	)}
																</span>
															</a>
														</div>
													</div>
												}
												key={record.id}
											>
												<p>
													<Row>
														<Col className="head" span={12}>
															Category
														</Col>
														<Col className="text" span={12}>
															{record?.category?.length
																? record?.category
																: "-"}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Type
														</Col>
														<Col span={12}>
															{record?.type?.length ? record?.type : "-"}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															HQ Location
														</Col>
														<Col className="text" span={12}>
															<div>
																{record?.area?.length
																	? record?.withArea?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Region
														</Col>
														<Col className="text" span={12}>
															<div>
																{record?.region?.length
																	? record?.region?.map((vo, io) => (
																			<div key={io}>{vo}</div>
																	  ))
																	: "-"}
															</div>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Related Games
														</Col>
														<Col className="text" span={12}>
															{GamesColumn("", record)}
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Basic Organization
														</Col>
														<Col className="text" span={12}>
															<>
																<div>
																	{record?.isBasic == 0
																		? "No"
																		: record?.isBasic == 1
																		? "Yes"
																		: ""}
																</div>
															</>
														</Col>
													</Row>
													<Row>
														<Col className="head" span={12}>
															Information
														</Col>
														<Col className="text" span={12}>
															<div className="Tabel_Information">
																{record?.__meta__?.exclusives_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.exclusives_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.exclusives_count >= 1
																			) {
																				history.push({
																					pathname: "/niko_knowledge_briefs",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		Knowledge Brief
																	</a>
																)}

																{record?.__meta__?.InsightFeed_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.InsightFeed_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.InsightFeed_count >= 1
																			) {
																				history.push({
																					pathname: "/news_articles/VANA",
																					state: { company: record?.id },
																				});
																			}
																		}}
																	>
																		VANA
																	</a>
																)}

																{record?.__meta__?.Tournament_count >= 1 && (
																	<a
																		style={
																			record?.__meta__?.Tournament_count >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (
																				record?.__meta__?.Tournament_count >= 1
																			) {
																				history.push({
																					pathname: "/esports",
																					state: { sponsor: record?.id },
																				});
																			}
																		}}
																	>
																		Tournaments
																	</a>
																)}

																{record?.skipTeams?.length >= 1 && (
																	<a
																		style={
																			record?.skipTeams?.length >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.skipTeams?.length >= 1) {
																				history.push({
																					pathname: "/Esports/Teams",
																					state: { sponsor: record?.id },
																				});
																				window.scrollTo({
																					top: 0,
																					behavior: "smooth",
																				});
																			}
																		}}
																	>
																		Teams
																	</a>
																)}

																{record?.skipIpo?.length >= 1 && (
																	<a
																		style={
																			record?.skipIpo?.length >= 1
																				? {}
																				: {
																						color: "black",
																						textDecoration: "none",
																						cursor: "default",
																				  }
																		}
																		className="a_style"
																		onClick={() => {
																			if (record?.skipIpo?.length >= 1) {
																				history.push({
																					pathname: "/Financial/Transactions",
																					state: { companyId: record?.id },
																				});
																			}
																		}}
																	>
																		M&A
																	</a>
																)}
															</div>
														</Col>
													</Row>
												</p>
											</Panel>
										))}
									</Collapse>
								)}
							</Col>
						</Row>
						{/* <Row style={{ padding: "10px 10px 0 10px", width: "100%" }}>
							<Col span={24}>
								{CompList?.error != 1009 &&
								CompList?.total
									? CompList?.total && (
											<Pagination
												current={
													parseInt(
														CompList && CompList?.page
													) || 1
												}
												total={
													(CompList &&
														CompList?.total) ||
													0
												}
												pageSize={
													CompList &&
													CompList?.perPage
												}
												onChange={onPageChange}
												showSizeChanger
												showQuickJumper
												showTotal={(total, range) =>
													`${range[0]}-${range[1]} of ${total} items`
												}
												locale={paginationLocale}
												itemRender={(page, type, originalElement) => {
													if (type === "page") {
														return <span>{page}</span>;
													}
													return originalElement;
												}}
												className="custom-pagination"
											/>
									  )
									: ""}
							</Col>
						</Row> */}
					</div>
				</Spin>
			</>
		</div>
	);
};
